import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"

// alice carousel
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import SEO from "../components/seo"
import BookFlow from "../components/book-flow"
import SakuraText from "../components/sakura-text"

import AboutImg from "../../static/assets/about-1-4-2.png"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    top1: file(relativePath: { eq: "fb-shop-01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800, maxHeight: 600, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    wish1: file(relativePath: { eq: "about-1-1-01-1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    wish2: file(relativePath: { eq: "about-1-3-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    wish3: file(relativePath: { eq: "about-1-2-01-1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    top2: file(relativePath: { eq: "fb-shop-02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800, maxHeight: 600, quality: 80) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          childImageSharp {
            fluid(
              maxWidth: 480
              maxHeight: 380
              quality: 80
              srcSetBreakpoints: [960, 1440]
            ) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
  }
`

const IdeaDiv = styled("div")`
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  border-width: 1px;
  text-align: center;
  padding: 2% 5% 2% 5%;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  margin-bottom: 20px;
`

const IdeaTitle = styled("p")`
  font-weight: 700;
  color: #ff2ea0;
`

const CenterImgWrap = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
`

const AboutImgCss = css`
  max-width: 100%;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
`

const getImgForAlice = img => {
  const srcSet = img.childImageSharp.fluid.srcSet
  const allRes = srcSet.match(/\/(.*?).jpg/g)

  const imgContainerCss = css`
    width: 100%;
    cursor: pointer;
  `
  const imgcss = css`
    height: 300px;
    max-width: 100%;
    max-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(${allRes[2]});
    @media only screen and (max-width: 900px) {
      background-image: url(${allRes[1]});
      height: 300px;
    }
    @media only screen and (max-width: 450px) {
      background-image: url(${allRes[0]});
      height: 150px;
    }
  `

  return (
    <div
      css={imgContainerCss}
      onClick={() => {
        window.open(
          "https://www.facebook.com/marketplace/item/1915957581758151"
        )
      }}
    >
      <div css={imgcss} />
    </div>
  )
}

const IdeaCard = ({ title, fluid, text }) => {
  return (
    <IdeaDiv>
      <IdeaTitle>{title}</IdeaTitle>
      <CenterImgWrap>
        <Img
          css={css`
            display: block;
            height: 150px;
            width: 150px;
          `}
          fluid={fluid}
        />
      </CenterImgWrap>
      <p>{text}</p>
    </IdeaDiv>
  )
}

const HomePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.fluid
    : ""

  const img1 = getImgForAlice(data.top1)
  const img2 = getImgForAlice(data.top2)

  return (
    <Layout>
      <SEO />
      {/*
      <div css={imgcss} />
      */}
      <Container className="home-banner">
        <Row>
          <Col fluid>
            <AliceCarousel
              infinite={true}
              autoPlay={true}
              autoPlayInterval={4000}
              disableButtonsControls={true}
            >
              {img1}
              {img2}
            </AliceCarousel>
          </Col>
        </Row>
        <Row>
          <Col lg={3} xl={3} md={4} sm={4} xs={12}>
            <img css={AboutImgCss} src={AboutImg} />
          </Col>
          <Col>
            <div>
              <h1 className="title">{frontmatter.title}</h1>
              <p className="tagline">{frontmatter.tagline}</p>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <BookFlow title="預約流程" showBanner={true} />
      <BlogListHome />
      <Container>
        <Row>
          <Col fluid>
            <SakuraText text="櫻花理念" size={28} />
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={12}>
            <IdeaCard
              title="櫻 花 願 景"
              fluid={data.wish1.childImageSharp.fluid}
              text="櫻花家居的願景是為您和您的家人消除室內空氣甲醛危害，尤其是孕婦、嬰兒、兒童、 老人提供最大保護。櫻花相信提供有效的家居空氣淨化服務，可有效改善香港家庭的健康。"
            />
          </Col>
          <Col md={4} xs={12}>
            <IdeaCard
              title="櫻 花 使 命"
              fluid={data.wish2.childImageSharp.fluid}
              text="櫻花家居一直專注去除甲醛，作為極富經驗的香港去甲醛公司，櫻花家居提供的不只是機械式的去甲醛服務，我們細心跟進每個項目、解答諮詢，其他公司做不到的，櫻花家居做得到；其他公司做得到的，櫻花家居做得更好。"
            />
          </Col>
          <Col md={4} xs={12}>
            <IdeaCard
              title="櫻 花 宗 旨"
              fluid={data.wish3.childImageSharp.fluid}
              text="專業，櫻花定期到日本尋找最新最有效方法處理甲醛問題；安全，相關的除甲醛產品均通過皮膚及有害測試，是屬安全標準；安心，櫻花承諾客人由查詢到制定方案以至完成服務會貼心跟進流程。"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default HomePage
