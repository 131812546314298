import React from "react"
import { Link, useStaticQuery, StaticQuery, graphql } from "gatsby"
import { RiArrowRightSLine } from "react-icons/ri"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import SakuraText from "./sakura-text"
import PostCard from "./post-card"

const PaddedBotDiv = styled("div")`
  padding-bottom: 30px;
`

const PostMaker = ({ data }) => (
  <section className="home-posts">
    <PaddedBotDiv>
      <SakuraText text="櫻花專題" />
    </PaddedBotDiv>
    <Container fluid="true">
      <Row>{data}</Row>
    </Container>
    <Link className="button" to="/櫻花專題">
      更多專題
      <span className="icon -right">
        <RiArrowRightSLine />
      </span>
    </Link>
  </section>
)

const BlogListHome = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { tags: { in: "專題" } } }
        limit: 6
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              pageTitle
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 640, maxHeight: 360, quality: 80) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const posts = data.allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => (
      <Col xl={4} lg={4} md={6} sm={6} xs={12} key={edge.node.id}>
        <PaddedBotDiv>
          <PostCard data={edge.node} />
        </PaddedBotDiv>
      </Col>
    ))
  return <PostMaker data={posts} />
}

export default BlogListHome
